.filter-col {

	background-color: #fff;

	font-size: PixelToRem(19);

	label {
		display: flex;
		justify-content: space-between;
		line-height: PixelToRem(27);
		gap: 1rem;
		.label-plain {
			hyphens: auto;
		}

		.number {
			display: inline-block;
			min-width: 40px;
			color: $text-light-grey;
			text-decoration: none !important;
		}
	}
	li {
		+ li {
			margin-top: 1rem;
		}
	}
}

.filter-col, .content-col {
	input[type=radio],
	input[type=checkbox] {
		position: absolute;
		left: -5000px;
		height: 1px;
		width: 1px;
		top: 0;
		&:checked {
			+ label {
				font-weight: 600;
				.number {
					color: $text-color;
				}
			}
		}

		&:focus,
		&:active {
			+ label {
				color: $red;
				.number {
					color: inherit;
				}
				.label-plain {
					text-decoration: underline;
				}
			}
		}
	}
}

.content-col {
	.filter-block {
		> input[type=checkbox] {
			&:checked {
				+ label {
					font-weight: 600;
					.number {
						color: $text-color;
					}
				}
			}
			&:focus,
			&:active {
				+ label {
					background-color: $red;
					color: $white;
				}
			}
		}
		.form-fields {

			input[type=checkbox] {
				&:focus,
				&:active {
					+ label {
						text-decoration: underline;
					}
				}
			}
			ul {
				max-height: 300px;
				overflow: auto;
			}
		}
	}

	#filter-box-6 {
		+ label {
			.counter {
				padding: 0 0.5rem;
			}
		}
	}
}

.wo-filter {
  @media (min-width: 960px) {
    min-width: 550px;
    width: 40rem !important;
  }
  @media (max-width: 960px) {
    width: auto;
  }
}

.plz-container-1 {
  @media (min-width: 960px) {
    width: 50%;
  }
}

.plz-container-2 {
  @media (min-width: 960px) {
    width: 50%;
  }
}

.info_icon {
  @media (min-width: 960px) {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('../images/_layout/info_icon.svg') 0px 0px no-repeat;
  }
}

.hide-mobile {
  @media (max-width: 960px) {
    display: none;
  }
}

.hide {
  display: none;
}

.btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  border-left: none;
  background-color: rgba(0,0,0,0);
  border-color: #949494;
  opacity: 1;
  opacity: initial
}

.single-filter {
	display: none;
	.combobox-list {
		display: inline-block;
		width: 8rem;

		li {
			padding: 4px 10px;
			font-size: inherit;
		}
		button {
			display: none;
		}
	}
}

.offer-filter {
	position: absolute;
	&:checked {
		~ .single-filter {
			display: block;

		}
	}
}

.content-col {
	.filter-block {

		position: relative;

		margin-bottom: 10px;

		label {
			width: 100%;
		}

		@media (min-width: 768px) { // > c768px
			margin-right: 10px;

			display: inline-block;
			margin-bottom: 1rem;

			label {

				width: auto;
			}
		}

		&.more-filter {

			margin-left: auto;
			margin-right: 0;
			text-align: right;
			label {

				width: auto;
			}

		}
		.counter {
			display: none;
			background-color: $white;
			color: $text-light-grey;
			text-align: center;
			border-radius: 12px;
			height: 24px;
			min-width: 24px;
			margin-left: 10px;
			justify-content: center;
		}
		.active {
			background-color: $bg-gray;
			color: white;
			.counter {
				display: flex;

			}
		}
		.single-filter {
			position: absolute;
			z-index: 105;
			background-color: $white;
			padding: 20px;
			top: 50px;
			border-radius: 5px;
			border: 1px solid rgba(0, 0, 0, 0.25);
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

			width: 100%;
			@media (min-width: 768px) { // > c768px
				width: auto;
			}
			p, li {

				input[type=checkbox] {

					+ label {
						//noinspection CssUnknownTarget
						background: url('../images/_layout/checkbox.svg') 2px 6px no-repeat;
						padding-left: 40px;
						&:hover {
							//noinspection CssUnknownTarget
							background: url('../images/_layout/checkbox-focus.svg') 0 6px no-repeat;
						}

					}
					&:focus,
					&:active {
						+ label {

							//noinspection CssUnknownTarget
							background: url('../images/_layout/checkbox-focus.svg') 0 6px no-repeat;
						}
					}
					&:checked {
						+ label {
							//noinspection CssUnknownTarget
							background: url('../images/_layout/checkbox-checked.svg') 2px 6px no-repeat;
							&:hover {
								//noinspection CssUnknownTarget
								background: url('../images/_layout/checkbox-checked-focus.svg') 0 6px no-repeat;
							}
						}
						&:focus,
						&:active {
							+ label {

								//noinspection CssUnknownTarget
								background: url('../images/_layout/checkbox-checked-focus.svg') 0 6px no-repeat;
							}
						}
					}

				}

				input {
					max-width: 100%;
				}
			}
			li {
				input[type=radio] {

					+ label {

						//noinspection CssUnknownTarget
						background: url('../images/_layout/radiobox.svg') 2px center no-repeat;
						padding-left: 40px;
						&:hover {
							//noinspection CssUnknownTarget
							background: url('../images/_layout/radiobox-focus.svg') 0 center no-repeat;
						}

					}
					&:focus,
					&:active {
						+ label {

							//noinspection CssUnknownTarget
							background: url('../images/_layout/radiobox-focus.svg') 0 center no-repeat;
						}
					}
					&:checked {
						+ label {
							//noinspection CssUnknownTarget
							background: url('../images/_layout/radiobox-checked.svg') 2px center no-repeat;
							&:hover {
								//noinspection CssUnknownTarget
								background: url('../images/_layout/radiobox-checked-focus.svg') 0 center no-repeat;
							}
						}
						&:focus,
						&:active {
							+ label {

								//noinspection CssUnknownTarget
								background: url('../images/_layout/radiobox-checked-focus.svg') 0 center no-repeat;
							}
						}
					}

				}

				input {
					max-width: 100%;
				}
			}
		}
		.form-fields {

			@media (min-width: 1200px) { // >= 1200px

				display: flex;
				gap: 1.5rem;
			}

			legend {
				margin-bottom: 8px;
			}
			legend,
			label {
				font-weight: 600;

				font-size: 19px;
			}
			li {
				font-size: 19px;

			}
			[type=radio] + label,
			[type=checkbox] + label {
				font-weight: 400;

				padding: 4px 0;
				width: PixelToRem(230);
				max-width: 100%;
			}
			.p,
			p {
				display: flex;
				gap: 1em;
				padding: 0;
				+ p {
					margin-top: 8px;
				}
			}

			input {
				border: 2px solid #000;

			}
			input,
			select {
				padding: 4px 12px;
			}

		}
		legend,
		label {
			font-size: 1rem;
			margin: auto;
			margin-left: 0;

		}
		> label {
			display: inline-flex;
			padding: 8px 32px 8px 12px;

			border: 1px solid $border-color;

			border-radius: 1.25rem;

			//noinspection CssUnknownTarget
			background: url('../images/_layout/icon-filter-arrow-down.svg') calc(100% - 8px) center no-repeat;

			&.active {
				//noinspection CssUnknownTarget
				background-image: url('../images/_layout/icon-filter-arrow-down-white.svg');
			}

		}
		> input {
			&:focus,
			&:active {
				+ label {
					color: $red;
					text-decoration: underline;
				}
			}
		}
	}

	#wo_plz {
		width: 8rem;
	}
	#wo_stadtteil {
		width: PixelToRem(300);
	}
	#umkreis,
	#wo_umkreis {
		width: 8rem;
	}

	@media (max-width: 768px) { // > c768px
		label[for=wo_plz],
		#wo_plz {
			width: 50%;
		}
		label[for=wo_umkreis],
		#wo_umkreis {
			width: 50%;
		}
	}

	.submit-row {
		padding-top: 10px;
		border-top: 1px solid;
		text-align: right;
		[type=radio] {
			&:focus {
				+ label {
					font-weight: 700;
				}
			}
		}
		[type=submit] {
			color: $white;
			background-color: $black;
			border-radius: 0;
			border: 0 none;
			padding: 6px 20px;
			margin-left: 1rem;
		}
	}

	.filter-row {

		@media (min-width: 768px) { // > c768px
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 10px;

      .sprachen-form-field {
        width: 350px;
      }
		}

		+ .filter-row {
			.filter-block {
				display: none;
				+ .filter-block {
					li {
						+ li {
							margin-top: 8px;
						}
					}
				}
			}
		}

		fieldset:last-child {
		}
		p {

			font-size: 1rem;
			padding-left: 8px;
			margin: auto 0;
		}
		.submit-row {
			margin-top: 10px;
		}

		.map-view {
			margin: auto 0 auto auto;

			label {
				padding: 0;

				position: relative;

				display: flex;
				flex-direction: row;
				align-items: center;

				.toggle {

					display: inline-block;
					width: 40px;
					height: 20px;

					position: relative;;
					margin: 0 0 0 16px;

					&:before,
					&:after {
						content: '';

						display: inline-block;
						position: absolute;
						left: 0;
						top: 0;
						width: 40px;
						height: 20px;

					}

					&:before {
						//noinspection CssUnknownTarget
						background: #fff url('../images/_layout/icon-toogle-bg.svg') center center no-repeat;
						opacity: 0.3333;
						transition: opacity 0.25s;

					}
					&:after {
						z-index: 100;
						width: 20px;
						left: -5px;
						transition: left 0.25s;

						//noinspection CssUnknownTarget

						background: transparent url('../images/_layout/icon-toggle-vg.svg') center center no-repeat;

					}
				}
			}
			input:checked {
				+ label {

					.toggle {
						&:before {
							opacity: 1;
						}
						&:after {
							left: calc(100% - 12px);
						}
					}
				}
			}
		}

	}
	.more-filter {
		label {
			//noinspection CssUnknownTarget
			background-image: url('../images/_layout/icon-more-filter.svg');

			background-position: 8px center;
			padding: 8px 12px 8px 58px;

		}
		input {
			&:focus,
			&:active {
				+ label {
					//noinspection CssUnknownTarget
					background-image: url('../images/_layout/icon-more-filter-hover.svg');
				}

			}
		}
	}

	.reset-filter {
		opacity: 0.3333;
		pointer-events: none;
		&.active {
			opacity: 1;
			pointer-events: auto;
		}
	}

  .reset-filter-suche {
    opacity: 0.3333;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

