.text-block {
	@media (min-width: 960px) { // >= 960px

		max-width: 1440px;
		margin: 50px auto auto;

		.content-header + & {
			margin-top: 24px;
		}

	}
	h2 {
		line-height: 1.25;
		margin-bottom: 1rem;
	}
	p {
		font-size: PixelToRem(20);
		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(24);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	.row {
		h2, h3, ul, ol, p {
			@media (max-width: 959px) { // 959px
				padding: 0 24px;
			}
		}
	}
	figure {
		margin-top: 1rem;

		@media (max-width: 959px) { // 959px
			padding: 0 24px;
		}

	}
	h3 {
		+ figure {
			margin-top: 0;
		}

	}
}
