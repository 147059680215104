.start-suche,
.carousel {

	@media (min-width: 960px) { // >= 960px
		height: 45vw;
		figure {
			height: 45vw;
		}

	}
	@media (min-width: 1200px) { // >= 960px
		min-height: 600px;
		height: auto;
		figure {
			height: auto;

			max-height: 600px;
		}

	}
}

.carousel {

  display: block;
  position: absolute;
	@media (min-width: 960px) { // >= 960px
		display: block;
	}

	left: 0;
	right: 0;

	max-height: 600px;

	.carousel-inner {
		max-height: 600px;

    img {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0.5;
      display: block;

      @media (max-width: 960px) { // >= 960px
        height: 300px;
        object-fit: cover;
      }
    }
	}

	@media (min-width: 576px) { // 960px
		width: 1400px;
		margin: auto;
		max-width: calc(100%);

	}
	@media (min-width: 960px) { // >= 960px
		max-width: calc(100% - 40px);

		.carousel-inner {
			max-width: calc(100%);
		}
	}
	figure {
		position: relative;
		display: block;

		margin: 0;

		background-color: rgba(0, 0, 0, 0.5);
		img {

			width: 100%;
			height: 100%;
		}

	}

	figcaption {
		position: absolute;
    font-size: 0.8rem;
		right: 40px;
		bottom: 20px;
		color: $white;
		z-index: 100;
	}

}

.offer-wann {
  ol,ul {
    padding-left: 2rem !important;
  }
  ul > li {
    list-style: disc !important;
  }
  ol > li {
    list-style: auto !important;
  }
}

.offer-kurzbeschreibung {
  ol,ul {
    padding-left: 2rem;
  }
  ul > li {
    list-style: disc !important;
  }
  ol > li {
    list-style: auto !important;
  }
}

#play {
	position: absolute;
	z-index: 50000;

	right: 1.5%;
	@media (min-width: 1200px) { // >= 1200px

		right: 3%;
	}

	bottom: PixelToRem(80);
	left: auto;
	top: auto;

	width: PixelToRem(60);
	height: PixelToRem(60);

	border-radius: 50%;

	border: 0 none;

	//noinspection CssUnknownTarget

	background: url('../images/_layout/button-pause.svg') center center no-repeat;

	&.paused {
		//noinspection CssUnknownTarget
		background-image: url('../images/_layout/button-play.svg');
	}

	.sr-only {
		display: none;
	}

	&:focus {
		outline: 2px solid rgba(255, 255, 0, 0.5);
	}

}
