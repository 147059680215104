.start-suche {

	padding: 24px;
	position: relative;
	margin: auto -12px;
	pointer-events: none;

	//noinspection CssUnknownTarget
	//background-image: url('../images/content/1-slide.jpg');

	@media (min-width: 960px) { // >= 960px
		background: none;

		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 1450px;
		max-width: 100%;
		margin: auto;
	}
	h1 {
		color: $white;
		font-weight: 600;
		margin: 0 0 10px;
		font-size: PixelToRem(28);
		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(36);
			margin: 0;
		}
	}
	p {
		margin: 0;
		max-width: 850px;
	}
	label {
		text-transform: uppercase;
		color: $text-color;
		font-weight: 600;
		display: block;
		margin-bottom: 5px;
	}
	select {
		margin: auto;
	}
	.text,
	form {
		margin: auto;
		width: PixelToRem(1070);
		max-width: 100%;
		@media (min-width: 960px) { // >= 960px
			max-width: calc(100% - 160px);
		}
		@media (min-width: 1200px) { // >= 1200px
			max-width: calc(100% - 280px);
		}
		p {
			pointer-events: auto;
		}
	}
	form {
		background-color: $white;

		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);

		margin: 24px auto 0;

		padding: 24px;

		@media (min-width: 960px) { // >= 960px
			margin: 0 auto 65px;
			display: flex;
			gap: 2rem;
			padding: 2.7% 4%;
			justify-content: space-between;
			align-items: flex-end;
		}

		p {
			margin-bottom: 24px;
			@media (min-width: 960px) { // >= 960px
				width: 45%;
				margin: 0;
			}

			&:last-child {
				@media (min-width: 960px) { // >= 960px
					width: 11%;
				}
			}
		}
		.btn-primary {
			background-color: $button-color;
			color: $white;
			border-radius: 0;
			margin: auto auto 0;
			width: 100%;
		}
	}
	select {
		color: $input-color;
		//noinspection CssUnknownTarget
		background-image: url('../images/_layout/icon-arrow-down.svg');
		border-color: $border-color;
	}

	.text {
		margin-bottom: 0;
		@media (min-width: 960px) { // >= 960px
			padding: 2% 4%;
		}
		p {
			color: $white;
			font-size: PixelToRem(22);
			margin: 0 0 10px;

			@media (min-width: 960px) { // >= 960px
				font-size: PixelToRem(28);
				margin: 0;
			}
		}
	}
}
