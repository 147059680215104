#to-top {
	display: none;
	position: fixed;
	z-index: 500;
	right: 2.5rem;
	
	@media (min-width: 1400px) { // >= 960px
		right: auto;
		left: calc(50vw + 1160px / 2 + 16px);
		box-shadow: none;
	}
	bottom: 2vh;
	text-align: center;
	
	&.active {
		border: 3px solid red !important;
	}
	img {
		display: block;
		margin: auto;
		@media (min-width: 1400px) { // >= 960px
			margin: auto auto 1rem;
			
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		}
	}
	a {
		text-decoration: none;
		span {
			background: #fff;
			padding: 1px 8px;
			display: inline-block;
			border-radius: 5px;
			
		}
		&:hover,
		&:focus,
		&:active {
			text-decoration: underline;
		}
	}
}
