.content-header {

  @media (max-width: 960px) {
    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 20px;
    }
  }

	position: relative;
	color: #fff;
	padding: 0;

	width: 1440px;

	max-width: 100%;

	&.container {
		max-width: calc(100vw);
		//outline: 4px solid lime !important;
	}
	main > & {

		margin-top: 0;

		margin-bottom: PixelToRem(24);
		@media (min-width: 960px) { // >= 960px
			margin-bottom: PixelToRem(70);
			margin-bottom: PixelToRem(50);
		}
	}

	.container {

		position: relative;
		padding: 24px;

		@media (min-width: 960px) { // >= 960px
			padding-top: 65px;
			//border: 3px solid lime;
			max-width: 1180px;
		}

	}

	figure {

		//max-width: calc(100% - 40px);
		//margin: auto;
		//border: 3px solid blue;
	}

	figure,
	img {
		height: PixelToRem(216);
		width: 100%;
		object-fit: cover;
	}

  @media (max-width: 960px) {
    figure,
    img {
      height: PixelToRem(265);
      width: 100%;
      object-fit: cover;
    }
  }

	.background-banner,
	.background-banner img {
		display: block;
    height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

	}
	.background-banner {
		@media (min-width: 960px) { // >= 960px
			max-width: calc(100% - 40px);
		}
    @media (max-width: 960px) { // >= 960px
      display: contents;
    }

		//border: 3px solid yellow;
		margin: 0 auto;

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	.topic-filter {
		min-height: PixelToRem(216);
		margin-bottom: PixelToRem(24);

		z-index: 100;

		@media (min-width: 960px) { // >= 960px
			margin-bottom: PixelToRem(60);
			margin-bottom: PixelToRem(40);
		}

		h1, h2 {
			display: inline-block;
			clear: both;
		}
	}

  .headline-wrapper {
    @media (max-width: 960px) { // >= 960px
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 23px;
      }
    }
    h1 {
      label {
        &:after {
          top: 2px;
          right: -45px;
        }
        &:after {
          //noinspection CssUnknownTarget
          background: url('../images/_layout/icon-arrow-down-white.svg') center center no-repeat;
          position: absolute;
          right: -50px;
          content: ' ';
          width: 45px;
          height: 45px;
          top: 4px;
          transition: transform 0.2s;
        }
        @media (max-width: 960px) { // >= 960px
          &:after {
            position: unset !important;
            display: inline-block !important;
            white-space: pre !important;
          }
        }
      }

      position: relative;

    }
  }

	h1 {
		font-weight: 600;
		margin-bottom: 20px;

		font-size: PixelToRem(36);
	}
	h2 {
		font-size: 24px;
		font-weight: 400;
	}

	input:focus {

		+ h2 label,
		+ h1 label {
			text-decoration: none;
		}
	}

	label {
	}
	.selected-value {
		text-decoration: underline;
		display: inline-block;

	}

	h2 {
		label {
			&:after {
				top: 2px;
				right: -45px;
			}
			&:after {
				//noinspection CssUnknownTarget
				background: url('../images/_layout/icon-arrow-down-white.svg') center center no-repeat;
				position: absolute;
				right: -50px;
				content: ' ';
				width: 30px;
				height: 30px;
				top: 4px;
				transition: transform 0.2s;
			}
      @media (max-width: 960px) { // >= 960px
        &:after {
          position: unset !important;
          display: inline-block !important;
          white-space: pre !important;
        }
      }
		}

		position: relative;

	}

	input {
		position: absolute;
		left: -5000px;
		overflow: hidden;
		height: 1px;
		width: 1px;
		top: 0;
	}
	.headline-wrapper {
		overflow-x: hidden;
		overflow-y: auto;
	}

	input:checked + .headline-wrapper + ul,
	input:checked + h1 + ul,
	input:checked + h2 + ul {
		display: inline-block;
		z-index: 20;
	}
	input:checked + .headline-wrapper h2 label {
		&:after {
			transform: scaleY(-1);
		}
	}
}

.content-header,
.combobox-list {
	ul {
		position: absolute;
		display: none;
		background: #fff;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);

	}
	li {
		@media (min-width: 580px) { // 580 px
			min-width: 320px;
		}
		border: 1px solid $border-color;
		+ li {
			border-top: 0 none;
		}

		a {
			padding: 10px;
			text-decoration: none;
			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
			}
		}
	}

}

.pseudo-select {
	a {
		display: block;
	}
	max-width: 75vw;
	ul, li, a {
		max-width: 100%;
	}

}

#auto-complete {
	top: 35px
}

.combobox-list {
	position: relative;
	z-index: 200;

	#auto-complete-plz,
	#auto-complete {
		[aria-selected	="true"] {
			background-color: $red;
			color: $white
		}

	}
}
