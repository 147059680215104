.content-cols {
	max-width: calc(100vw - 48px);
	margin: 0 auto;
	@media (min-width: 960px) { // >= 960px
		display: flex;
		gap: 2%;
	}
}

.filter-col {
	min-width: 23%;
	margin-bottom: 40px;
}

.content-col {
	min-width: 75%;
	

	
}

.border-box {
	ol, ul, li, p {
		&:empty {
			display: none;
		}
	}
	
	> a {
		text-decoration: none;
		

		padding: 24px 16px;
		display: flex;
		flex-direction: column-reverse;
		@media (min-width: 960px) { // >= 960px
			padding: 38px 24px;
			gap: PixelToRem(20);
			flex-direction: row;
		}
		
		
		
		&:hover,
		&:focus,
		&:active {
			h2 {
				text-decoration: underline;
				.meta {
					text-decoration: none;
				}
			}
			.tags li,
			.date-time,
			.location {
				color: $text-color;
				
			}
		}
		
	}
	
	margin-bottom: PixelToRem(20);
	border: 1px solid $border-color;
	
	h2 {
		font-size: PixelToRem(24);
		
		@media (max-width: 440px) { // < 440px
			hyphens: auto;
		}
		
	}
	p {
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.4;
		margin-bottom: PixelToRem(20);
		@media (min-width: 960px) { // >= 960px
			margin-bottom: PixelToRem(30);
		}
	}
	.more {
		font-size: PixelToRem(18);
		font-weight: 400;
		margin-bottom: PixelToRem(10);
	}
	figure {
		img {
			margin-bottom: 5px;
		}
		@media (min-width: 960px) { // >= 960px
			margin: 0 0 auto auto;
			max-width: 30%;
			
			
		}
	}
}
#filter-form {
	.load-more {
		text-align: center;
		margin-top: 30px;
		margin-bottom: 30px;
		display: none;
		a {
			display: inline-block;
			font-size: PixelToRem(18);
			text-decoration: none;
			border: 1px solid $border-color;
			padding: 15px 45px;
			margin: auto;
			text-transform: uppercase;
			
		}
	}
}
