select {
	border: 2px solid $black;
	width: 100%;
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;
	//noinspection CssUnknownTarget
	background: $white url(../images/_layout/icon-select-arrow.png) no-repeat right;
	background-size: contain;
}
select::-ms-expand {
	display: none;
}

select, input, button,.btn-primary {
	font-size: PixelToRem(18);
	padding: PixelToRem(8);
}
