.row {
	.functions {
		
		margin-bottom: 0;
		li, p {
			display: inline-block;
			font-size: PixelToRem(16);
			line-height: PixelToRem(21);
			@media (min-width: 576px) { // 576px
				
				font-size: PixelToRem(18);
				line-height: PixelToRem(25);
			}
			padding: 8px 10px 8px 36px;
			
		}
	
		.print {
			//noinspection CssUnknownTarget
			background: transparent url('../images/_layout/icon-print.svg') 6px center no-repeat;
		}
		
		.share {
			position: relative;
			//noinspection CssUnknownTarget
			background: transparent url('../images/_layout/icon-share.svg') 4px center no-repeat;
		}
	}
}

.copy-block {
	display: none;
	padding: 1rem 1.5rem;
	position: absolute;
	z-index: 100;
	left: 1rem;
	top: 110%;
	white-space: nowrap;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.125);
	background-size: 1.25rem 1.25rem;
	border: 1px solid $border-color;
	background: $bg-light-grey;
	&.open {
		display: inline-block;
	}
	* {
		vertical-align: middle;
	}
	.url {
		max-width: 200px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
	}
	a {
		//noinspection CssUnknownTarget
		background: url("/images/_layout/copy-line-icon.svg") 0.75rem center no-repeat;
		display: inline-block;
		color: $red;
		padding-left: 2.5rem;
		
	}
}
