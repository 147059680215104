.locations {
	columns: 2;
	column-gap: 1rem;
	@media (min-width: 960px) { // >= 960px
		
		columns: 4;
		column-gap: 1.5rem;
	}
	
	margin-bottom: 60px;
	
	h4 {
		margin: auto;
	}
	h4, li {
		font-size: PixelToRem(16);
		line-height: PixelToRem(22);
		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(19);
			line-height: PixelToRem(25);
		}
	}
	li {
		margin-bottom: 4px;
	}
	
	a {
		text-decoration: none;
		&:hover,
		&:focus,
		&:active {
			color: $red;
			text-decoration: underline;
		}
	}
}

#map {
	margin-bottom: 2rem;
}