.banner {
	margin: 50px auto;
	width: auto;
	padding: 0;
	img {
		width: 100%;
		object-fit: cover;
		height: 340px;
		max-height: calc(100vw / 4);
	}
}