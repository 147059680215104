#full-loader .spinner-text {
	height: auto;
}

@-webkit-keyframes animate-loader {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}

@keyframes animate-loader {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}

$sedo-blue: $red;
#full-loader {
	min-height: 300px;
	position: fixed;
	left: 50%;
	margin-left: -100px;
	top: 35vh;
	transform: scale(1);
	z-index: 100002;
	@media (max-width: 768px) {
		max-width: 200px;
	}
	.spinner-text {
		border: 1px solid #cccccc;
		text-align: center;
		background-color: #fff;
		padding: 1rem;
		font-size: 1.5rem;
		position: absolute;
		line-height: inherit;
		width: auto;
		min-width: 32em;
		margin-left: 100px;
		left: -16em;
		top: -6em;
		border-radius: 10px;
		@media (max-width: 768px) {
			min-width: 0;
			left: 24px;
			width: calc(100vw - 80px);
			margin-left: calc(-50vw + 120px);
			
			p {
				max-width: calc(100vw - 100px);
				padding: 0 20px;
				hyphens: none;
				strong {
					max-width: 80vw;
					line-height: 2.5rem;
				}
			}
		}
		
		strong {
			color: $sedo-blue;
		}
		p {
			line-height: 1.25rem;
		}
	}
	
	> div {
		transform: scale(1);
		position: relative;
		background: none;
		width: 200px;
		height: 200px;
		> div {
			top: 80px;
			left: 94px;
			width: 12px;
			height: 40px;
			background: $sedo-blue;
			border-radius: 8px;
			position: absolute;
			animation: animate-loader 1s linear infinite;
			&:nth-of-type(1) {
				animation-delay: -0.5s;
				transform: rotate(0deg) translate(0, -60px);
			}
			&:nth-of-type(2) {
				animation-delay: -0.4166666666666667s;
				transform: rotate(30deg) translate(0, -60px);
			}
			&:nth-of-type(3) {
				animation-delay: -0.33333333333333337s;
				transform: rotate(60deg) translate(0, -60px);
			}
			&:nth-of-type(4) {
				animation-delay: -0.25s;
				transform: rotate(90deg) translate(0, -60px);
			}
			&:nth-of-type(5) {
				animation-delay: -0.16666666666666669s;
				transform: rotate(120deg) translate(0, -60px);
			}
			&:nth-of-type(6) {
				animation-delay: -0.08333333333333331s;
				transform: rotate(150deg) translate(0, -60px);
			}
			&:nth-of-type(7) {
				animation-delay: 0s;
				transform: rotate(180deg) translate(0, -60px);
			}
			&:nth-of-type(8) {
				animation-delay: 0.08333333333333337s;
				transform: rotate(210deg) translate(0, -60px);
			}
			&:nth-of-type(9) {
				animation-delay: 0.16666666666666663s;
				transform: rotate(240deg) translate(0, -60px);
			}
			&:nth-of-type(10) {
				animation-delay: 0.25s;
				transform: rotate(270deg) translate(0, -60px);
			}
			&:nth-of-type(11) {
				animation-delay: 0.33333333333333337s;
				transform: rotate(300deg) translate(0, -60px);
			}
			&:nth-of-type(12) {
				animation-delay: 0.41666666666666663s;
				transform: rotate(330deg) translate(0, -60px);
			}
		}
	}
	&.small {
		> div {
			transform: scale(0.5);
			> div {
				background: #777;
				height: 24px;
				width: 5px;
			}
		}
	}
}

// optional - if you need a background behind the loader
#full-loader-bg {
	content: ' ';
	position: absolute;
	background-color: rgba(0, 0, 0, 0.35);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100001;
}

#full-loader-bg,
#full-loader {
	
	&.fadeOut {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.25s, visibility 0s 0.5s;
	}
}