footer {
	
	h2 {
		font-size: PixelToRem(20);
		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(24);
		}
		
	}
	ul {
		padding: 0;
	}
	li {
		list-style: none;
	}
	li, p {
		font-size: PixelToRem(17);
	}
	.inner {
		
		strong {
			display: block;
			margin-bottom: 10px;
		}
		@media (min-width: 960px) { // >= 960px
			max-width: 1200px;
			margin: auto;
			display: inline;
		}
	}
}

#footer {
	background-color: $bg-light-grey;
	@media (max-width: 959px) { // 960px
		.inner {
			padding: 24px;
			
		}
		aside {
			+ aside {
				margin-top: 36px;
			}
		}
	}
	@media (min-width: 960px) { // >= 960px
		.inner {
			display: flex;
			aside {
				width: 33.3333%;
				padding: PixelToRem(60) 1rem;
			}
		}
	}
	li {
		img {
			margin-right: 1rem;
		}
		+ li {
			margin-top: 10px;
		}
	}
	a {
		color: black;
	}
	
	select {
		margin-top: PixelToRem(18);
		
	}
	
	#ort-selection {
		display: flex;
		
	
		
		a {
			border: 0 none;
			
			margin-left: 4px;
			
			margin-top: 18px;
			display: block;
			display: none;
			color: $white;
			background-color: $black;
			padding: 10px 20px;
			font-size: 1.125rem;
			line-height: 27px;
			
		}
	}
	
	
}

#sub-footer {
	background-color: $black;
	p, a {
		font-size: 1rem;
		color: $white;
	}
	p, ul {
		padding: PixelToRem(20) 1rem;
	}
	ul {
		
		@media (max-width: 959px) { // 960px
			text-align: center;
			li {
				+ li {
					margin-top: 8px;
				}
			}
		}
		margin: auto;
		+ p {
			&:before {
				content: ' ';
				display: block;
				height: 1px;
				background-color: $white;
				position: relative;
				top: -1rem;
			}
		}
	}
	.inner {
		@media (min-width: 960px) { // >= 960px
			ul {
				display: flex;
				gap: 1.5rem;
				justify-content: space-between;
			}
		}
	}
	a {
		position: relative;
		
	}
}




