.listing {



	@media (min-width: 960px) { // >= 960px
		max-width: 1114px;
		margin: auto;
		margin-top: 70px;
	}
	.row {
		@media (min-width: 960px) { // >= 960px
			gap: 16px
		}
	}
	ul {
		text-align: center;
	}
	li {
		border: 1px solid $border-color;
		list-style: none;
		text-align: left;
		padding: 0;
		margin: 0 auto 36px ;
		max-width: calc(100vw - 48px);
		@media (min-width: 768px) { // 768px
			width: calc(50% - 24px);
		}
		@media (min-width: 960px) { // >= 960px
			width: calc(33% - 8px);
			margin: 0 0 36px ;
		}
		@media (min-width: 1200px) { // >= 1200px
			width: calc(25% - 12px);
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 40px;

	}

	a {
		text-decoration: none;

		height: 100%;
		display: flex;
		flex-direction: column;

		&:hover,
		&:focus,
		&:active {
			h3 {
				text-decoration: none;
				color: $red
			}
		}

	}
	figure {
		order: 1;
		margin: 0;
		width: 100%;
		display: flex;
		flex-direction: column;

		min-height: 255px;
		img {
			width: 100%;
		}

		@media (min-width: 960px) { // >= 960px
			img {
				max-height: 188px;
				height: 100%;
				object-fit: cover;;
			}
		}
	}
	figcaption {
		display: block;
		padding: 5px 24px 14px;
		font-size: PixelToRem(10);
		color: $small-light-text;

	}

	h3 {
		order: 2;
		text-decoration: underline;

		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-arrow-right.svg') calc(100% - 19px) 7px no-repeat;
		padding: 0 36px 16px 24px;

	}

}



