.filter-row {

	@media (min-width: 960px) and (max-width: 1150px) { // >= 960px

		max-width: 60vw;

	}

	+ .search-result,
	+ .result-page {
		margin-top: 40px;
	}
}

.result-page {
	&:empty {
		border: 30px solid blue !important;
		display: none;
	}
}

.search-result,
#result-box {
	@media (min-width: 960px) and (max-width: 1150px) { // >= 960px
		max-width: 60vw;
	}
}

.search-result {



	.border-box {
		> a {
			div {
				@media (min-width: 960px) { // >= 960px
					max-width: 75%;
					overflow: auto;
				}
			}
		}

		h2 {
			font-size: PixelToRem(19);
			.meta {
				display: block;
				font-size: 1rem;
				font-weight: 400;
				//font-style: italic;
				margin-bottom: 1rem;
				text-decoration: underline;

			}

		}

		p {
			font-size: PixelToRem(19);


		}
		.date-time,
		.location {
			padding-left: 2rem;
			margin-bottom: 0.5rem;
		}

		figcaption {
			display: block;
			font-size: 12px;
			color: $small-light-text;
			text-align: center;
			margin-bottom: 1.5rem;
			@media (min-width: 960px) { // >= 960px
				margin-bottom: 0;
				text-align: right;
			}
		}
	}

}

.container {

	.date-time {

		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-calendar.svg') center left no-repeat;

		padding-left: 2rem;

	}
	.location {
		padding-left: 2rem;

		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-map-pin.svg') center left no-repeat;

	}
	.fee {
		padding-left: 2rem;

		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-tag.svg') center left no-repeat;

	}
	.contact {
		padding-left: 2rem;

		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-person.svg') center left no-repeat;

	}

	.tags {
		@media (min-width: 580px) { // 580 px

			display: flex;
			gap: 1rem;
			flex-wrap: wrap;

		}



		margin-bottom: 1rem;

		li {
			background: $border-color;
			padding: 4px 1rem;
			background-color: $bg-light-grey;
			border-radius: 1rem;
			overflow: hidden;
		}
		.for-free {
			background: #C7FFD6;
			border-radius: 0;
			position: relative;
			padding-right: 1.5rem;
			&:before,
			&:after {
				content: '';
				display: block;
				background: #fff;
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				right: -0.75rem;
				top: -0.75rem;
				transform: rotate(45deg);
			}
			&:after {
				top: auto;
				bottom: -0.75rem;
			}
		}
	}
	.fee-comment {
		margin-top: -1rem;
		padding: 0 24px 38px;
	}
}

#share-link {
	border: 2px solid $text-color;
	border-radius: 1.5rem;
	width: 100%;
}

.border-box {
	@media (max-width: 768px) { // < 768

		figure {
			padding: 0;
			margin-top: 0;
		}
		.tags {

			li {
				margin-bottom: 1rem;


			}
		}
	}
}


