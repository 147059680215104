body {
	font-family: 'Roboto Flex', Roboto, Arial, sans-serif;
	font-size: 1rem;
	scroll-behavior: smooth;

}

header, main, footer {
	background-color: $white;
}

main {
	> .container,
	> .container-fluid {
		margin-top: PixelToRem(50);
		margin-bottom: PixelToRem(50);

		&:first-child {
			margin-top: 0;
		}

	}
}

body {
	.container-fluid,
	.container {

		@media (min-width: 576px) { // 576px
			max-width: calc(1440px);

			background-clip: content-box;
			padding: 0 20px;
			.row {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	@media (min-width: 960px) { // >= 960px
		padding-bottom: 20px;
	}
}

td ul {
  padding-left: 30px;
}

td ul li {
  list-style: disc;
}

td ol {
  padding-left: 30px;
}

td ol li {
  list-style: decimal;
}

dd ul {
  padding-left: 30px;
}

dd ul li {
  list-style: disc;
}

dd ol {
  padding-left: 30px;
}

dd ol li {
  list-style: decimal;
}

ul {
	padding: 0;
}

li {
	list-style: none;
}

.sr-only {

	left: -2000px;

	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	overflow: hidden;

	color: #000;

	position: absolute;

	z-index: 100;

}

h2 {
	font-weight: 600;
	line-height: 1.4;

	font-size: PixelToRem(28);
	@media (min-width: 960px) { // >= 960px
		font-size: PixelToRem(36);
	}
	small {

		display: block;
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: PixelToRem(10);

		font-size: PixelToRem(16);
		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(19);
		}
	}

}

h3 {
	font-weight: 600;
	font-size: PixelToRem(20);
	@media (min-width: 960px) { // >= 960px
		font-size: PixelToRem(24);
	}
	.row p + & {
		margin-top: 1rem;
	}
}

main {
	a {

		color: $text-color;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: $red

		}
	}

	img {
		max-width: 100%;
	}
}

a {
	text-decoration: underline;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
}

.error {
	font-size: 2rem;
	color: #ff0000;
	background: #fff url('../images/_layout/icon-alert.svg') center left no-repeat;
	background-size: 2rem;
	padding-left: 2.75rem;

}

input:disabled {
  background-color: #b6b6b6;
}

input#topics:focus + .headline-wrapper h1 label {
  border: 2px solid black;
  border-radius: 4px;
}

input#age:focus + .headline-wrapper h2 label {
  border: 2px solid black;
  border-radius: 4px;
}

#loader {
  z-index: 1;
  margin-left: 10%;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { left:-100px; opacity:0 }
  to { left:0px; opacity:1 }
}

@keyframes animatebottom {
  from{ left:-100px; opacity:0 }
  to{ left:0; opacity:1 }
}

#searchDiv {
  text-align: center;
}

.multiple-select {
  background: none !important;
}

body.disable-scroll {
  overflow: hidden;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 0px !important;
}

@media (max-width: 960px) { // >= 960px
  .no-mobile {
    display: none !important;
  }
}
