
#sprungmarken {
	display: inline;
	float: left;

	.sr-only {
		&:focus,
		&:active {
			left: 10px;
			width: auto;
			white-space: nowrap;
			top: 10px;
			height: auto;
			overflow: visible;
			display: block;
			padding: 8px 16px;
			background-color: white;
			font-weight: 700;
			color: $red;
			border: 1px solid;
			border-radius: 4px;
			box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
			font-size: 1rem;

			clip: auto;
			clip-path: none;

		}
	}

}

.nav-main {

	padding-bottom: 5px;

	background-color: $white;
	p {
		margin: 0;
	}
	.container {

		@media (min-width: 580px) { // 580 px
			max-width: calc(100vw - 48px);
		}

		display: block;
		padding: 0 24px 24px;

		@media (min-width: 960px) { // >= 960px
			padding: 0 8px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		> div {
			align-items: unset;
			display: flex;
			flex-direction: column;
			&:first-child {
				align-self: start;
			}
			&:last-child {
				p {
					text-align: center;
					margin: 2px 0 24px;
					@media (min-width: 960px) { // >= 960px
						text-align: right;
						margin: 10px 0;
					}
				}
			}
			+ div {
				@media (min-width: 768px) and (max-width: 959px) { // 768px

					flex-direction: row-reverse;
					> * {
						align-items: center;
					}
					&:last-child {

						p {
							margin: auto;
						}
					}
				}
			}
		}
	}

	.navbar-brand {

		font-size: PixelToRem(28);

		padding: 18px 0 10px;

		@media (min-width: 580px) { // 580 px

			font-size: PixelToRem(36);
			padding: 10px 0 10px;

		}
		font-weight: bold;
		display: inline-block;

		max-width: calc(100% - 40px);
		white-space: normal;

	}
	ul {

		margin: 4px 0 0;
	}

	#navbar {
		background-color: #fff;
		@media (max-width: 959px) { // 959px
			position: absolute;

			left: calc(100% + 20px);
			top: 72px;
			z-index: 210;
			width: 100vw;
			box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);

			display: none;
			transition: left 0.3s;

		}
	}
	.navbar-toggler {
		@media (max-width: 959px) { // 959px
			right: 24px;
			top: 24px;
			border: 0 none;
			padding: 0;

			//noinspection CssUnknownTarget
			background: #fff url('../images/_layout/icon-burger-button-close.svg') center center no-repeat;
			&.collapsed {
				//noinspection CssUnknownTarget
				background-image: url('../images/_layout/icon-burger-button.svg');

			}

		}
	}

	li {
		list-style: none;
		text-align: center;
		@media (min-width: 960px) { // >= 960px
			display: inline-block;
		}
		a {
			font-size: PixelToRem(20);
			color: black;
			display: block;
			padding: 10px;
			text-decoration: none;
			font-weight: bold;
			&:hover,
			&:focus,
			&:active,
			&.active {
				color: $red;
			}

		}
		+ li {
			@media (min-width: 960px) { // >= 960px
				margin-left: 25px;
			}
		}
	}

	.logo {
		max-width: PixelToRem(260);
		padding: 3px 15px;
		margin-bottom: 8px;
		max-height: 60px;
	}
	form {
		display: inline-block;
		position: relative;
		max-width: 100%;

		@media (min-width: 580px) { // px

			padding-right: 10px;
		}

	}
	input {
		border-radius: 0;

		border: 1px solid $border-color;
		max-width: 100%;

	}

	@media (max-width: 959px) { // 959px
		.navbar-toggler {
			display: block;
		}
		ul {

		}
	}

	button {
		display: inline-block;
		background-color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 40px;
		z-index: 100;

		padding-top: 4px;

		border: 1px solid $border-color;
		border-left: 0 none;

		img {
			transition: opacity 0.2s;
			width: 23px;
			height: 24px;
		}

	}

	#auto-complete {

	}
	form {
		button {
			&:hover,
			&:focus,
			&:active {
				border: 1px solid $rot !important;

				img {
					opacity: 0;
				}

				//noinspection CssUnknownTarget
				background: $rot url('../images/_layout/icon-suche-hover.svg') center center no-repeat !important;

			}
			img {
				display: inline-block;
				height: 100%;
				width: 100%;
				position: static;
			}
		}
	}
}





