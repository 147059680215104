.most-visited {
	background-color: $bg-light-grey;
	h2 {
		margin-bottom: 4px
	}
	.row {
		@media (min-width: 960px) { // >= 960px
			display: flex;
			max-width: 1114px;
			margin: auto;
			gap: 16px;
		}
	}
	.inner {
		
		padding: 24px;
		@media (min-width: 960px) { // >= 960px
			width: calc(50% - 12px);
			padding: 80px 20px;
		}
	}
	li {
		list-style: none;
		+ li {
			margin-top: 9px;
		}
	}
	a {
		font-size: PixelToRem(22);
		display: inline-block;
		padding-right: 40px;
		//noinspection CssUnknownTarget
		background: url('../images/_layout/icon-arrow-right.svg') calc(100% - 10px) 8px no-repeat;
		&:hover,
		&:focus,
		&:active {
			//noinspection CssUnknownTarget
			background-image: url('../images/_layout/icon-arrow-right-hover.svg');
			
		}
	}
	h2 {
		a {
			font-size: inherit;
			line-height: 1.5;
			min-width: 250px;
			@media (min-width: 580px) { // 580 px
				
				min-width: 310px;
			}
			//noinspection CssUnknownTarget
			background: url('../images/_layout/icon-arrow-right-big.svg') calc(100% - 10px) calc(100% - 8px) no-repeat;
			
			&:hover,
			&:focus,
			&:active {
				//noinspection CssUnknownTarget
				background-image: url('../images/_layout/icon-arrow-right-big-hover.svg');
				
			}
		}
		+ h2 {
			margin-top: 40px;
		}
	}
	
}