.calendar {
	
	table {
		border-collapse: collapse;
	}
	display: flex;
	gap: 2rem;
	
	th, td {
		padding: 0;
		font-size: PixelToRem(14);
		text-align: right;
		&[colspan] {
			text-align: center;
		}
		
		a {
			display: block;
			padding: 4px 8px;
			
			text-decoration: none;
			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
			}
			
		}
	}
	.today {
		a {
			background-color: $bg-light-grey;
		}
	}
	.selected {
		a {
			background-color: $red;
			color: $white;
			&:hover,
			&:focus,
			&:active {
				color: yellow;
			}
		}
	}
	.date-details {
		display: none;
	}
}

#user-selection {
	label {
		display: block;
		font-weight: 400;
		font-size: 14px;
	}
	
	.flex {
		display: flex;
		
		gap: 1rem
	}
}



