#language-icon {
  background-image: url('/images/_layout/language.svg'); /* Hier den Pfad zur SVG-Datei angeben */
  background-size: contain; /* SVG wird vollständig und proportional angepasst */
  background-repeat: no-repeat;
  background-position: center; /* Positioniere das Icon in der Mitte */
  border: none;
  cursor: pointer;
}

#searchDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#language-dropdown {
  display: none; /* Standardmäßig ausblenden */
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 150px;
}

.language-option {
  display: block; /* Block-Level für Buttons */
  width: 100% !important; /* Füllen den Dropdown-Bereich */
  padding: 8px;
  cursor: pointer !important;
  text-align: left;
  position: revert !important;
  background: none;
  border: none;
  box-sizing: border-box; /* Verhindert Breitenverschiebung durch Padding */
}

.language-option:hover {
  background-color: #f0f0f0;
}

#language-icon {
  background-image: url('/images/_layout/language.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
}
