.provider {
	
	h2 {
		color: $text-light-grey;
		font-size: 1rem;
		font-weight: lighter;
	}
	.box {
		padding: PixelToRem(10) PixelToRem(12);
		
		@media (min-width: 960px) { // >= 960px
			padding: PixelToRem(10) PixelToRem(0);
		}
		
		footer & {
			padding-bottom: PixelToRem(40);
			border: 2px solid yellow !important;
		}
		+ .box {
			margin-top: 2rem;
			@media (min-width: 960px) { // >= 960px
				margin-left: 3rem;
				margin-top: 0;
			}
		}
		
	}
	.nrw {
		display: flex;
		margin: auto;
		max-width: PixelToRem(1200);
		align-items: center;
		gap: 1rem;
		
		strong {
			display: block;
			font-size: PixelToRem(13);
			line-height: 1.2;
			max-width: PixelToRem(250);
		}
	}
	h2 {
		margin-bottom: PixelToRem(10);
		@media (min-width: 960px) { // >= 960px
			margin-bottom: PixelToRem(30);
		}
	}
	@media (min-width: 960px) { // >= 960px
		.inner {
			display: flex;
			gap: 3rem;
			max-width: PixelToRem(1200);
		}
	}
	.logo {
		width: PixelToRem(235);
	}
	.logo-nrw {
		width: PixelToRem(60);
	}
}
