.offer-details {

	@media (min-width: 960px) { // >= 960px

		max-width: calc(100vw - 48px);
		width: 1200px;

	}
	.row {
		margin-bottom: 24px;
		flex-direction: column;

		max-width: calc(100vw - 40px);

		@media (max-width: 768px) { // < 768
			margin-left: auto;
			margin-right: auto;
		}

		> * {
			padding-left: 12px;
			padding-right: 12px;
		}
		> table {
			margin-left: 12px;
			margin-right: 12px;

			@media (max-width: 768px) { // < 768
				width: 95%;

			}
		}
	}

	@media (max-width: 768px) { // < 768
		.tags {
			li {
				margin-bottom: 1rem;
				
				
			}
		}
	}

	#map {
		margin-left: 12px;
		margin-right: 12px;

		max-width: calc(100% - 24px);

		@media (min-width: 960px) { // >= 960px

			max-width: calc(66% - 20px);
		}
	}

	.bcn {
		background-color: $bg-light-grey;
		padding: 6px 10px;
		p {
			margin: 0;
		}
		.back {

			display: inline-block;
			padding-left: 12px;

			//noinspection CssUnknownTarget

			background: transparent url('../images/_layout/icon-back.svg') center left no-repeat;

		}

	}

	h1 {
		font-size: PixelToRem(28);
		line-height: PixelToRem(36);

		@media (min-width: 960px) { // >= 960px
			font-size: PixelToRem(36);
			line-height: PixelToRem(40);
		}
		font-weight: 600;
		max-width: 900px;


		.meta {
			font-weight: 400;
			text-decoration: underline;
			font-size: PixelToRem(20);
			line-height: PixelToRem(28);
			@media (min-width: 960px) { // >= 960px
				font-size: PixelToRem(27);
				line-height: PixelToRem(34);
			}
			display: block;
			margin-bottom: 18px;
		}
	}

	h2 {
		font-size: PixelToRem(24);
		line-height: PixelToRem(30);

	}
	h3 {
		font-size: PixelToRem(19);
		line-height: PixelToRem(25);

	}

	p {

		font-size: PixelToRem(16);
		line-height: PixelToRem(21);
		@media (min-width: 576px) { // 576px

			font-size: PixelToRem(19);
			line-height: PixelToRem(25);
		}
	}




	.tags {
		margin: 0 0 2rem;

	}


	

	.offer-compact {
		> figure,
		> .row {
			min-width: 66%;
		}

		position: relative;
		z-index: 100;

		@media (min-width: 960px) { // >= 960px

			display: flex;
			gap: 1.5rem;
			align-items: flex-start;
			max-width: calc(100vw - 40px);
		}

	}

	figure {
		img {
			margin-bottom: 10px;
		}
	}
	figcaption {
		font-size: 14px;
		color: $text-light-grey;
	}
	.offer-data {
		background-color: $bg-light-grey;
		padding: 24px;
		@media (min-width: 960px) { // >= 960px
			padding: 35px;

			width: 33%;

			> :last-child {
				margin-bottom: 0;
			}
		}
		
		
	}
	dl {
		font-size: PixelToRem(17);
		line-height: PixelToRem(21);
		@media (min-width: 576px) { // 576px

			font-size: PixelToRem(19);
			line-height: PixelToRem(25);
		}
		margin-bottom: 40px;
	}
	dt {
		margin-bottom: 8px;
		padding-left: 30px;
	}
	dd {
		margin-bottom: 18px;

	}
	.btn {
		display: inline-block;
		border: 1px solid;
		padding: 9px 18px;
		background-color: $white;
		border-radius: 0;
		font-size: PixelToRem(16);
		line-height: PixelToRem(21);
		@media (min-width: 576px) { // 576px

			font-size: PixelToRem(18);
			line-height: PixelToRem(25);
		}

	}

	p {
		@media (min-width: 960px) { // >= 960px

			width: 760px;
			max-width: 66%;
		}	}
	table {
		width: calc(100vw - 40px);
		width: calc(65%);

		.tags {
			li {
				@media (min-width: 576px) { // 576px

					margin: 0;
				}
			}
		}

	}
	th {
		width: 25vw;
		@media (min-width: 576px) { // 576px

			width: 200px;
		}
	}
	th, td {

		@media (max-width: 576px) { // < 576px
			hyphens: auto;
		}

		padding: 4px;
		font-size: PixelToRem(16);
		line-height: PixelToRem(23);

		@media (min-width: 576px) { // 576px
			padding: 4px;
			font-size: PixelToRem(19);
			line-height: PixelToRem(25);
		}
		vertical-align: baseline;

	}
	.compact-table {
		th {
			font-weight: 400;
		}
		th, td {
			padding: 2px;
		}
	}

	.table-inner-headline {
		display: block;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 24px;
	}
	ul {
		+ .table-inner-headline {
			margin-top: 24px;
		}
	}

	.bullet-list {
		padding-left: 24px;
		li {
			list-style-type: disc;
		}
	}
	.logo-list {
		li {
			margin-bottom: 1rem;
			@media (min-width: 576px) { // 576px

				display: inline-block;
				+ li {
					margin-left: 20px;
				}
			}
		}
	}
	.text-center {
		p {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.other-offer {
		display: flex;
		flex-wrap: wrap;
		gap: PixelToRem(24);
		h3 {
			.meta {
				font-weight: 400;
				text-decoration: underline;
				font-style: italic;
				display: block;
				margin-bottom: 10px;
			}
		}

		.tags {
			margin-bottom: 0;
			li{
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		> li {
			margin: auto 0 0;
			@media (min-width: 576px) { // 576px
				width: calc(50% - 0.75rem);
				margin: 0;

			}
			@media (min-width: 960px) { // >= 960px

				width: calc(33.333% - 1rem);
			}
			> a {
				flex-direction: column;
			}
		}
	}
}

